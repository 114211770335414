"use client";

import "react-tooltip/dist/react-tooltip.css";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { useRouter } from "next/navigation";
import { sessionHandler } from "@/lib/features/user/userSlice";
import { useAppDispatch } from "@/lib/hooks";
export default function AuthCheck() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    data: session,
    status
  } = useSession();
  useEffect(() => {
    console.log("AuthCheck", session, status);
    if (session === undefined) return;
    if (status === "loading") return;
    if (status === "unauthenticated") {
      router.push("/login");
    }
    if (session) {
      router.push("/chat");
      if (!!session.accessToken) {
        dispatch(sessionHandler(session.accessToken));
      }
    }
  }, [session, status]);
  return null;
}